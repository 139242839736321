import React from "react"

const JoinAccuIndex = ({
  latest_offer = {},
  latest_offer_description = {},
  latest_offers = [],
}) => {
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offers">
              <h2 style={{ color: "#156eb6", paddingTop: "25px" }}>
                {latest_offer.text}
              </h2>
              <p>{latest_offer_description.text}</p>
            </div>
            <div className="offerCards col-lg-12">
              <div className="row">
                {latest_offers.map((offer, offerIndex) => (
                  <div className="col-lg-4 col-sm-6" key={'sdf'+offerIndex}>
                    <div className="card offerCard">
                      <figure>
                        <img src={offer.thumbnail.url} alt="ddd"/>
                      </figure>
                      <div className="card-body">
                        <h6>
                          <a href={offer.offer_link.text} target="_blank" rel="noreferrer">
                            {offer.offer_title.text}
                          </a>
                        </h6>
                        <p>{offer.offer_description.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinAccuIndex
